import React from "react"
import { injectIntl, intlShape, FormattedMessage } from "react-intl"
import { compose } from "recompose"

import withLayout from "../layout"
import withPageContext from "../pageContext"

import Link from "../components/LocalizedLink"
import SEO from "../components/SEO"

const NotFoundPage = ({ intl, pageContext }) => (
  <React.Fragment>
    <SEO
      title={intl.formatMessage({ id: "404.title" })}
      lang={pageContext.locale}
      description={intl.formatMessage({ id: "404.description" })}
    />
    <main>
      <h1>
        <FormattedMessage id="404.title" />
      </h1>
      <Link to="/">
        <FormattedMessage id="back.home" />
      </Link>
    </main>
  </React.Fragment>
)

NotFoundPage.propTypes = {
  intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(NotFoundPage)
